import {
  alpha, Container, Grid, Typography, useTheme,
} from '@mui/material'
import {
  DarkModeIconButtonForColorScheme,
  FlexGrowRow, FlexRow, LinkEx,
} from '@xylabs/sdk-react'
import React from 'react'

import { NewsletterSection } from '../common/index.ts'
import Company from './Company.tsx'
import Equity from './Equity.tsx'
import Headquarters from './Headquarters.tsx'
import Other from './Other.tsx'
import Products from './Products.tsx'
const AppFooter: React.FC = () => {
  const theme = useTheme()
  return (
    <footer>
      <NewsletterSection />
      <FlexRow paddingY={4}>
        <Container>
          <Grid container justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} md={4}>
              <Headquarters alignItems="flex-start" />
            </Grid>
            <Grid item xs={6} md={2}>
              <Company alignItems="flex-start" />
            </Grid>
            <Grid item xs={6} md={2}>
              <Products alignItems="flex-start" />
            </Grid>
            <Grid item xs={6} md={2}>
              <Equity alignItems="flex-start" />
            </Grid>
            <Grid item xs={6} md={2}>
              <Other alignItems="flex-start" />
            </Grid>
          </Grid>
        </Container>
      </FlexRow>
      <FlexRow bgcolor={alpha(theme.palette.primary.main, 0.05)} paddingY={2}>
        <Container>
          <FlexGrowRow justifyContent="space-between" width="100%" sx={{ paddingY: { md: 2, xs: 4 } }}>
            <FlexGrowRow width="100%" justifyContent="flex-start">
              <Typography variant="caption">
                ©
                {' '}
                {new Date().getFullYear()}
                {' '}
                XY LABS -&nbsp;
                <LinkEx to="/privacy">PRIVACY POLICY</LinkEx>
                -&nbsp;
                <LinkEx to="/terms">TERMS OF SERVICE</LinkEx>
&nbsp;-&nbsp;
                <LinkEx to="/sales-terms">TERMS OF SALE</LinkEx>
                {' '}
&nbsp;-&nbsp;
                <LinkEx to="/sweeps">COIN SWEEPSTAKES RULES</LinkEx>
              </Typography>
            </FlexGrowRow>
            <FlexGrowRow sx={{ justifyContent: { md: 'flex-end', xs: 'center' } }}>
              <DarkModeIconButtonForColorScheme />
            </FlexGrowRow>
          </FlexGrowRow>
        </Container>
      </FlexRow>
    </footer>
  )
}

export default AppFooter
