import { XyUserEventHandler } from '@xylabs/pixel'
import { XyLabsTheme } from '@xylabs/react-theme'
import {
  Fbq, FlexRow, Gtag, InvertibleMuiThemeProvider, ScrollToTop,
  UserEventsProvider,
} from '@xylabs/sdk-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'

import { PixelProvider, SettingsLoader } from './Contexts/index.ts'
import { AppRoutes } from './Pages/index.ts'

Fbq.init('339003476728548')
Gtag.init('G-TCJLRQCJ2H', 'AW-989965544', ['jobs.xylabs.com', 'xylabs.com', 'localhost', 'localhost:3000'])

const userEvents = new XyUserEventHandler()

const AppBody: React.FC = () => {
  return (
    <PixelProvider id="xylabs.com">
      <FlexRow margin={3} />
      <ParallaxProvider>
        <BrowserRouter>
          <ScrollToTop />
          <AppRoutes />
        </BrowserRouter>
      </ParallaxProvider>
    </PixelProvider>
  )
}

const AppThemeBody: React.FC = () => {
  return (
    <UserEventsProvider userEvents={userEvents}>
      <InvertibleMuiThemeProvider theme={XyLabsTheme}>
        <AppBody />
      </InvertibleMuiThemeProvider>
    </UserEventsProvider>
  )
}

export const App: React.FC = () => {
  if (globalThis.location.hostname === 'xyfindables.com') {
    globalThis.location.href = 'https://xyfindit.com'
  }
  if (globalThis.location.hostname === 'xyfindit.com') {
    globalThis.location.href = 'https://xylabs.com/products/xy-find-it'
  }
  return (
    <SettingsLoader>
      <Helmet defaultTitle="XY Labs" titleTemplate="%s | XY Labs">
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-TCJLRQCJ2H" />
        <script async src="https://connect.facebook.net/en_US/fbevents.js" />
      </Helmet>
      <AppThemeBody />
    </SettingsLoader>
  )
}
