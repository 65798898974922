import {
  Container, Grid, Step, StepButton, StepContent, Stepper, Typography,
} from '@mui/material'
import {
  ButtonEx, FlexGrowCol, FlexRow, LinkEx,
} from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import type { ReactNode, RefObject } from 'react'
import React, {
  useEffect, useRef, useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { DepositNotificationOptionsAccordion } from './DepositNotificationOptionsAccordion.tsx'
import {
  TzeroAssetPage, TzeroButton, TzeroDepositRequest, TzeroDepositSuccess,
} from './img/index.ts'

interface stepsProps {
  description: ReactNode
  image?: string
  label: string
}

const stepsData: stepsProps[] = [
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom paddingBottom={1}>
          To log in to your account, visit&nbsp;
          <LinkEx href="https://www.tzero.com/investors/login" target="_blank">
            https://www.tzero.com/investors/login
          </LinkEx>
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom paddingBottom={1}>
          Don&apos;t have an account? Visit&nbsp;
          <LinkEx href="https://www.tzero.com/investors/register" target="_blank">
            https://www.tzero.com/investors/register
          </LinkEx>
          &nbsp;and you&apos;ll see the form to create an account for tZERO Markets.
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          If you&apos;d like detailed instructions for creating an account with screenshots, please click
          <LinkEx to="#tzeroAccount" target="_blank">
            &nbsp;here
          </LinkEx>
          .
        </Typography>
      </FlexGrowCol>
    ),
    label: 'Log into your tZERO Markets Account',
  },
  {
    description: (
      <FlexGrowCol alignItems="center">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Next, you&apos;ll need to go to the XY Labs, Inc. asset page:
          {' '}
          <LinkEx href="https://www.tzero.com/investors/asset/XYLB" target="_blank">
            https://www.tzero.com/investors/asset/XYLB
          </LinkEx>
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          You can also get there from the home page, or by clicking the &quot;Markets&quot; tab at the top of the screen. Select XY Labs from the list
          of assets, and you&apos;ll get to XY&apos;s specific asset page.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroAssetPage,
    label: 'Navigate to the XY Labs, Inc. asset page',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Click the &quot;Deposit Shares&quot; button to begin your request for deposit.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroButton,
    label: 'Click "Deposit Shares"',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Your unrestricted shares of XY Labs Class A Common Stock will be automatically displayed in the deposit request, as long as your tZERO email
          matches the email Vertalo has on file. You will be prompted to review the information and authorize
          {' '}
          <LinkEx to="/investors/portal">Vertalo</LinkEx>
          {' '}
          to transfer the shares to your tZERO Markets account by providing an e-signature.
        </Typography>
        <Typography variant="body2" textAlign="left" gutterBottom>
          Note: If your unrestricted XY Labs shares do not show up when requesting your deposit, then there is a linkage issue between your tZERO and
          Vertalo account. If this is the case, please email
          {' '}
          <LinkEx href="mailto:investor-relations@xylabs.com" target="_blank">
            investor-relations@xylabs.com
          </LinkEx>
          {' '}
          for assistance.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroDepositRequest,
    label: 'View Deposit Details',
  },
  {
    description: <DepositNotificationOptionsAccordion />,
    image: TzeroDepositSuccess,
    label: 'Deposit Request Status',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Once your deposit request status has changed, you&apos;ll receive an email notification.
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          If your request was
          {' '}
          <strong>successful</strong>
          , you will receive a email confirmation that your XY Shares have been deposited into your
          tZERO Markets account.
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          If your request was
          {' '}
          <strong>unsuccessful</strong>
          , you will be prompted via email to reach out to
          {' '}
          <LinkEx href="mailto:investor-relations@xylabs.com" target="_blank">
            support@tzero.com
          </LinkEx>
          {' '}
          for more information.
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          Once you&apos;ve completed the above steps, you&apos;ll be ready to trade your XY Shares on the tZERO ATS!
        </Typography>
      </FlexGrowCol>
    ),
    label: 'Deposit Request Email Notification',
  },
]

export const RequestDepositInstructions: React.FC = () => {
  const anchors: Record<string, RefObject<HTMLDivElement>> = { tzeroRequestDepositStep1: useRef<HTMLDivElement>(null) }
  const location = useLocation()
  const anchor = anchors[location.hash.slice(1)]

  useEffect(() => {
    if (anchor?.current) {
      anchor?.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [anchor])

  const [activeStep, setActiveStep] = useState(0)

  const handleStepChange = (step: number) => () => {
    setActiveStep(step)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    globalThis.location.href = '#tzeroRequestDepositStep1'
    setActiveStep(0)
  }

  return (
    <SectionSpacingRow>
      <Container>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h4" textAlign="center" gutterBottom sx={{ paddingBottom: { md: 4, xs: 2 } }}>
              How to Request a Deposit in tZERO
            </Typography>
            <FlexGrowCol alignItems="stretch">
              <Stepper orientation="vertical" nonLinear activeStep={activeStep} id="tzeroRequestDepositStep1" ref={anchors.tzeroRequestDepositStep1}>
                {stepsData.map((step, index) => (
                  <Step key={step.label}>
                    <StepButton color="inherit" onClick={handleStepChange(index)}>
                      <Typography variant="h6">{step.label}</Typography>
                    </StepButton>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <FlexRow sx={{ mb: 2 }} width="100%" justifyContent="flex-start">
                        <div>
                          <ButtonEx variant="contained" onClick={index === stepsData.length - 1 ? handleReset : handleNext} sx={{ mr: 1, mt: 1 }}>
                            {index === stepsData.length - 1 ? 'Start from Beginning' : 'Next'}
                          </ButtonEx>
                          <ButtonEx disabled={index === 0} onClick={handleBack} to="#tzeroRequestDepositStep1" sx={{ mr: 1, mt: 1 }}>
                            Back
                          </ButtonEx>
                        </div>
                      </FlexRow>
                      {step.image
                        ? (
                            <FlexGrowCol
                              sx={{
                                backgroundImage: `url(${step.image})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                height: { md: '500px', xs: '300px' },
                              }}
                            >
                            </FlexGrowCol>
                          )
                        : null}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}
