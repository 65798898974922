/* eslint-disable @stylistic/max-len */
import type { SimpleCardProps } from '@xyo-network/react-card'

export const FaqData: SimpleCardProps[] = [
  {
    desc: "Vertalo is a technology-first transfer agent that offers an improved shareholder experience and digital communications. By coordinating with its network of integrated partners, Vertalo reduces systemic friction in private capital formation and improves access to secondary liquidity. Alongside Vertalo's long-time partner tZERO, Vertalo and XY Labs will offer an improved shareholder experience. We are working directly with Vertalo to move Shareholder accounts from our previous transfer agent, 'Broadridge', to Vertalo's system.",
    headline: 'What is Vertalo?',
    href: 'https://support.xy.company/hc/en-us/articles/5205351765147',
  },
  {
    desc: 'If you have any questions about your account, your stock, or something else, please direct them to the XY Labs Investor Relations Team. Click the "Learn More" button below to contact the team, or email investor-relations@xylabs.com. Do not contact Vertalo, as they will be unable to assist you with XY Labs questions.',
    headline: 'How do I contact Vertalo?',
    href: 'https://support.xy.company/hc/en-us/requests/new',
  },
  {
    desc: 'Vertalo does not have phone support. Remember: If you have any questions about your account, your stock, or something else, please direct them to the XY Labs Investor Relations Team. Please do not contact Vertalo, as they will be unable to assist you with XY Labs questions. XY Labs will be able to assist you with your account and your questions!',
    headline: 'Does Vertalo have phone support?',
    href: 'https://support.xy.company/hc/en-us/requests/new',
  },
  {
    desc: "If you need to transfer the stock to a new owner, you'll need to contact XY for instructions on how to officially transfer the stock. To do so, Click the 'Learn More' button below to contact the team, or email investor-relations@xylabs.com for instructions. Please do not contact Vertalo. XY Labs will be able to assist you with your account and your questions!",
    headline: 'How do I transfer XY Labs Stock?',
    href: 'https://support.xy.company/hc/en-us/requests/new',
  },
  {
    desc: "We have kept our shareholder's best interest in mind when making the decision to move to Vertalo. We chose Vertalo because they have the supported technology to make the process of secondary trading on tZero be as seamless as possible. In addition, we believe Vertalo has an improved shareholder platform and provides an overall better shareholder experience.",
    headline: 'Why did we switch to Vertalo?',
    href: 'https://support.xy.company/hc/en-us/articles/5205351765147',
  },
  {
    desc: 'The last price XY Labs Stock was sold for was $8 per share. Currently, XY Labs Stock has no determined price or value because it is not currently on an exchange. By listing on tZERO, XY Labs Stock will be traded. This means there will be a market price associated with the stock, but we cannot speculate on what that price will be.',
    headline: 'What is the value of XY Labs Stock?',
    href: 'https://support.xy.company/hc/en-us/articles/360023917314',
  },
  {
    desc: "We are working directly with Vertalo to move Shareholder accounts from our previous transfer agent, 'Broadridge', to Vertalo's system. This means that you will no longer need to interact with Broadridge  or your Broadridge account, as the process to move your holdings to Vertalo is being done for you. Your Broadridge account information and XY Labs Stock information will be moved over to Vertalo for you. Once this is complete, you will be able to view everything in your new Vertalo account, such as how many shares you hold, your contact information, etc.",
    headline: 'What will happen with my Broadridge Acccount?',
    href: 'https://support.xy.company/hc/en-us/articles/5205351765147',
  },
  {
    desc: 'No need to worry. There are multiple proofs of purchase for your transaction and you are in our records. Please contact us if you would like an electronic copy or another proof of purchase.    ',
    headline: 'I lost my investment certificate!',
    href: 'https://support.xy.company/hc/en-us/articles/360024523134',
  },
  {
    desc: "We have an answer. Please take a moment to puruse investor questions in our help center, and if you need further assistance, just use the 'Submit a Request' button to get in touch with our specialists.",
    headline: 'I have another question.    ',
    href: 'https://support.xy.company/hc/en-us/sections/360006409073',
  },
]
