import { Toolbar } from '@mui/material'
import {
  ButtonEx, DarkModeIconButtonForColorScheme, FlexRow,
} from '@xylabs/sdk-react'
import React from 'react'

import { NavbarDropdown } from './Dropdown.tsx'
import MobileMenu from './MobileMenu.tsx'
import { navbarData } from './navbarData.tsx'

const SystemToolBar: React.FC = () => {
  return (
    <Toolbar disableGutters>
      <FlexRow sx={{ display: { md: 'inherit', xs: 'none' } }}>
        {navbarData.map((section, index) => {
          return <NavbarDropdown name={section.name} to={section.to} key={index} sectionItems={section.sectionItems}></NavbarDropdown>
        })}
      </FlexRow>
      <ButtonEx color="primary" to={{ pathname: '/get-started' }} marginLeft={1} variant="contained" sx={{ display: { md: 'flex', xs: 'none' } }}>
        Get Started
      </ButtonEx>
      <DarkModeIconButtonForColorScheme />
      <MobileMenu />
    </Toolbar>
  )
}

export default SystemToolBar
